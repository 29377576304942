$(() => {
    $('#ModalAddAlert').on('show.bs.modal', function (event) {
        manageAddressInputs($(this));
    });

    $('#ModalEditAlert').on('show.bs.modal', function (event) {
        manageAddressInputs($(this));

        let alertData = $(event.relatedTarget).data('alert');

        $(this).find('input[name="name"]').val(alertData.name);

        $(this).find('select[name="departments[]"]')
            .val(alertData.departments.map(function (department) {
                    return department.id;
                })
            );
        $(this).find('select[name="newPositions[]"]')
            .val(alertData.new_positions.map(function (newPosition) {
                    return newPosition.id;
                })
            );

        $(this).find('input[name="begin_at"]').val(alertData.begin_at_date);
        $(this).find('input[name="end_at"]').val(alertData.end_at_date);

        $(this).find('select[name="patient_types[]"]')
            .val(alertData.patient_types.map(function (patientType) {
                    return patientType.id;
                })
            );

        if (alertData.send_mail) {
            $(this).find('input[name="send_mail"]').prop('checked', true);
        } else {
            $(this).find('input[name="send_mail"]').prop('checked', false);
        }

        if (alertData.available_to_recruiters) {
            $(this).find('input[name="available_to_recruiters"]').prop('checked', true);
        } else {
            $(this).find('input[name="available_to_recruiters"]').prop('checked', false);
        }

        if (alertData.address) {
            $(this).find('input[name="fill_address"]').prop('checked', true);

            $(this).find('#editCollapseAddress').addClass('show');

            $(this).find('input[name="address[line_1]"]').val(alertData.address.line_1).prop('required', true);

            $(this).find('input[name="address[line_2]"]').val(alertData.address.line_2);

            if (alertData.address.city.id) {
                var currentCityOption = new Option(alertData.address.city.name, alertData.address.city.id, true, true);
                $(this).find('select[name="address[city_id]"]').append(currentCityOption).prop('required', true);
            }
        }

        $(this).find('.select2').trigger('change');

        $(this).find('input[type="range"]').val(alertData.range).trigger('change');

        $(this).find('form').attr('action', '/alert/' + alertData.id);
    });

    $('#ModalEditAlert').on('hidden.bs.modal', function (event) {
        $(this).find('.select2').val(null).trigger('change');
        $(this).find('input:text').val(null).trigger('change');
        $(this).find('input[type=range]').val(0).trigger('change');
        $(this).find('input[name="fill_address"]').prop('checked', false);
        $(this).find('#editCollapseAddress').removeClass('show');
        $(this).find('select[name="address[city_id]"]').val(null).trigger('change');
        $(this).find('form').attr('action', '');
    });
});

function manageAddressInputs(modal) {
    let addressLine1 = modal.find('input[name="address[line_1]"]');
    let addressLine2 = modal.find('input[name="address[line_2]"]');
    let addressCity = modal.find('select[name="address[city_id]"]');

    modal.find('input[name="fill_address"]').change(function (event) {
        let checked = $(event.target).prop('checked');

        addressLine1.prop('required', checked);
        addressCity.prop('required', checked);

        addressLine1.prop('disabled', !checked);
        addressLine2.prop('disabled', !checked);
        addressCity.prop('disabled', !checked);
    });
}
