$(() => {

	/*
	$(".select2").select2({
		dropdownParent: $(this).parent('.modal-content')
	});
	*/

	$("#AlertContracts").select2({
		dropdownParent: $('#ModalAddAlert .modal-content')
	});

});
