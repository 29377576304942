import Agency from "./Agency";
import Model from "./Model";

export default class Applicant extends Model {
    acceptsAllContracts()
    {
        return 'all' === this.accepted_contracts;
    }

    getRelationships()
    {
        return {
            agencies: Agency,
        }
    }
}
