<template>
    <div class="d-flex flex-column">
        <div v-if="message" class="d-flex justify-content-center p-3 color-dark-raspberry">{{ message }}</div>

        <div class="week-buttons d-flex mb-3">
            <a class="btn mr-4" :class="{ 'bg-nato-blue': 'current' === week }" @click="week = 'current'">Semaine en cours</a>
            <a class="btn" :class="{ 'bg-fuel-yellow': 'next' === week }" @click="week = 'next'">Semaine à venir</a>
        </div>
        <div v-if="loading" class="loading d-flex justify-content-center mb-5">
            Chargement ...
        </div>
        <div v-else>
            <div class="d-flex flex-column flex-md-row">
                <div class="card col-md-5 pb-3 h-100 mb-3 mb-md-0">
                    <div class="d-flex align-items-center">
                        <h2 class="p-3 h3">Rayon d'action</h2>
                    </div>

                    <div class="p-3">
                        <div class="d-flex mb-3">
                            <span>Autour de mon domicile</span>
                            <label class="toggle ml-2" >
                                <input type="checkbox" v-model="isHomeCity" value="1">
                                <span class="slider-checkbox"></span>
                                <span class="labels" data-on="OUI" data-off="NON"></span>
                            </label>
                        </div>
                        <div v-if="!isHomeCity" class="mb-3">
                            <p class="mb-0 mt-2">Situation géographique : </p>
                            <select-2 :initial="availabilities ? availabilities.city : null" :is-disabled="isHomeCity" :name="'city'" :data-url="citiesRoute" @selected="(selected) => citySelected(selected)"></select-2>
                        </div>
                        <dl class="row">
                            <dt class="col-md-7">Rayon de déplacement</dt>
                            <dd class="col-md-5">{{ availabilities ? availabilities.range : '' }} kms</dd>
                        </dl>
                        <input type="range" min="0" max="100" step="5" v-model="availabilities.range">
                        <div class="row justify-content-center">
                            <a class="mt-5 btn btn-fuel-yellow text-white" @click="saveAvailabilities()">Enregistrer la localisation</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-7 ml-md-3 p-0">
                    <div class="card mb-5">
                        <div v-if="loading">
                            Chargement ...
                        </div>

                        <div v-else>
                            <staff-plus-availabilities @save-availabilities="saveAvailabilities()" @availability-updated="updateAvailability($event)" @availability-cleared="clearAvailability()" v-bind:week="week" v-bind:periods="periods" v-bind:availabilities="availabilities"></staff-plus-availabilities>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'StaffPlus',
        data() {
            return {
                step: null,
                citiesRoute: route('api.public.city.index'),
                isHomeCity: true,
                selectedCityName: null,
                availabilities: {},
                week: 'current',
                currentTab: null,
                message: null,
                periods: {},
                loading: true,
                selectCitiesDisabled: true,
                selectedCity: null,
            }
        },
        methods: {
            citySelected(selected) {
                this.availabilities.city_id = selected
                this.selectedCityName = $('#city option:selected').text()
                this.isHomeCity = false
            },

            clearAvailability() {
                this.availabilities = {}

                this.saveAvailabilities()
            },

            updateAvailability(availability) {
                this.availabilities = availability
            },

            saveAvailabilities() {
                if (this.availabilities) {
                    axios.put(
                        route('api.applicant.staffPlusAvailability.update', { week : this.week }),
                        this.availabilities
                    )
                    .then(response => {
                        this.selectedCityName = null
                        this.loadAvailabilities(this.week)
                    })
                    .catch(error => {
                        if (error.response.status == 422) {
                            this.error = "Vous devez renseigner vos disponibilités"
                        }
                    });
                } else {
                    axios.delete(route('api.applicant.staffPlusAvailability.destroy', { week : this.week }))
                        .then(response => {
                            this.selectedCityName = null
                            this.loadAvailabilities(this.week)
                        })
                        .catch(error => {
                            if (error.response.status == 422) {
                                this.message = "Erreur lors de la suppression des disponibilites"
                            }
                        })
                    ;
                }
            },

            loadPeriods(type = 'current') {
                this.loading = true

                axios.get(route('api.applicant.staffPlusPeriod.index', {week : type}))
                    .then(response => {
                        this.periods = response.data.data
                    })
                    .catch(error => {
                        this.error = "Erreur lors de la récupération des périodes"
                    })
                    .finally(() => {
                        this.loading = false;
                        this.setCurrentTab(type)
                    });
            },

            loadAvailabilities(type = 'current') {
                this.loading = true

                axios.get(route('api.applicant.staffPlusAvailability.show', {week : type}))
                    .then(response => {
                        this.availabilities = response.data.data
                        
                        if (this.availabilities.periods) {
                            this.availabilities.periods = Object.assign({}, this.availabilities.periods)
                        }

                        if (this.availabilities.city.id) {
                            this.citySelected(this.availabilities.city.id)
                        }
                    })
                    .catch(error => {
                        this.error = "Erreur lors de la récupération des disponibilites"
                    })
                    .finally(() => {
                        this.loading = false;
                        this.setCurrentTab(type)
                        this.isHomeCity = true

                        if (this.availabilities.city) {
                            this.selectCitiesDisabled = false
                            let option = new Option(this.availabilities.city.name, this.availabilities.city.id, false, false)
                            $('#city').append(option).trigger('change')
                            $('#city').val(this.availabilities.city.id).trigger('change')
                            this.isHomeCity = false
                        }
                    });
            },

            setCurrentTab(type = 'current') {
                this.currentTab = 'staff-plus-' + type + '-week'
            }
        },
        watch: {
            isHomeCity(newIsHomeCity, oldIsHomeCity) {
                if (newIsHomeCity && this.availabilities.city_id) {
                    this.availabilities.city_id = null
                }
            },
            week(newWeek, oldWeek) {
                this.loadPeriods(newWeek)
                this.loadAvailabilities(newWeek)
            },
        },
        mounted: function() {
            this.loadPeriods()
            this.loadAvailabilities()
        }
    }
</script>

<style scoped lang="scss">
    .loading {
        font-size: 3rem;
        color: #132C45;
    }

    .week-buttons {
        .btn {
            background-color: transparent;
            border: 1px solid #132C45;
            color: #132C45;

            &:hover {
                background-color: #EFEEEF;
            }

            &.bg-fuel-yellow {
                color: white;
                border-color: transparent;

                &:hover {
                    background-color: #d4771f !important;
                }
            }

            &.bg-nato-blue {
                color: white;
            }

            &.bg-nato-blue:hover {
                background-color: #142a44 !important;
            }
        }
    }

    .toggle {
        --width: 70px;
        --height: calc(var(--width) / 3);

        position: relative;
        display: inline-block;
        width: var(--width);
        height: var(--height);
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
        border-radius: var(--height);
        cursor: pointer;
    }

    .toggle input {
        display: none;
    }

    .toggle .slider-checkbox {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: var(--height);
        background-color: #ccc;
        transition: all 0.4s ease-in-out;
    }

    .toggle .slider-checkbox::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: calc(var(--height));
        height: calc(var(--height));
        border-radius: calc(var(--height) / 2);
        background-color: #fff;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
        transition: all 0.4s ease-in-out;
    }

    .toggle input:checked+.slider-checkbox {
        background-color: #d48f31;
    }

    .toggle input:checked+.slider-checkbox::before {
        transform: translateX(calc(var(--width) - var(--height)));
    }

    .toggle .labels {
        position: absolute;
        top: 4px;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 12px;
        font-family: sans-serif;
        transition: all 0.4s ease-in-out;
    }

    .toggle .labels::after {
        content: attr(data-off);
        position: absolute;
        right: 5px;
        color: #707070;
        opacity: 1;
        transition: all 0.4s ease-in-out;
    }

    .toggle .labels::before {
        content: attr(data-on);
        position: absolute;
        left: 5px;
        color: #ffffff;
        opacity: 0;
        text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
        transition: all 0.4s ease-in-out;
    }

    .toggle input:checked~.labels::after {
        opacity: 0;
    }

    .toggle input:checked~.labels::before {
        opacity: 1;
    }

    i {
        color: #132C45;
    }

    .hidden {
        display: none;
    }

    input[type=range] {
        -webkit-appearance: none;
        margin: 10px 0;
        width: 100%;
    }

    input[type=range]:focus {
        outline: none;
    }

    input[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        height: 7px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 0px 0px 0px #000000;
        background: #d48f31;
        border-radius: 10px;
        border: 0px solid #000000;
    }

    input[type=range]::-webkit-slider-thumb {
        box-shadow: 0px 0px 1px #000000;
        border: 1px solid #000000;
        height: 20px;
        width: 20px;
        border-radius: 30px;
        background: #ffffff;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -7px;
    }

    input[type=range]:focus::-webkit-slider-runnable-track {
        background: #d48f31;
    }

    input[type=range]::-moz-range-track {
        width: 100%;
        height: 7px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 0px 0px 0px #000000;
        background: #d48f31;
        border-radius: 10px;
        border: 0px solid #000000;
    }

    input[type=range]::-moz-range-thumb {
        box-shadow: 0px 0px 1px #000000;
        border: 1px solid #000000;
        height: 20px;
        width: 20px;
        border-radius: 30px;
        background: #ffffff;
        cursor: pointer;
    }

    input[type=range]::-ms-track {
        width: 100%;
        height: 7px;
        cursor: pointer;
        animate: 0.2s;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    input[type=range]::-ms-fill-lower {
        background: #d48f31;
        border: 0px solid #000000;
        border-radius: 20px;
        box-shadow: 0px 0px 0px #000000;
    }

    input[type=range]::-ms-fill-upper {
        background: #d48f31;
        border: 0px solid #000000;
        border-radius: 20px;
        box-shadow: 0px 0px 0px #000000;
    }

    input[type=range]::-ms-thumb {
        box-shadow: 0px 0px 1px #000000;
        border: 1px solid #000000;
        height: 20px;
        width: 20px;
        border-radius: 30px;
        background: #ffffff;
        cursor: pointer;
    }

    input[type=range]:focus::-ms-fill-lower {
        background: #d48f31;
    }

    input[type=range]:focus::-ms-fill-upper {
        background: #d48f31;
    }
</style>
