import bsCustomFileInput from 'bs-custom-file-input';

$(() => {
    bsCustomFileInput.init()

    $('form.autosubmit').on('change', function () {
        $(this).submit();
    });

    /**
     * Ranges
     */
    $('.form-alert-range').each(function(idx, el)
    {
        let label = $($(this).attr('data-info')),
            postfix = $(this).attr('data-postfix');

        $(this).change(function(){
            label.html($(this).val() + (postfix ? ' ' + postfix : ''));
        })

        $(this).on("input", function(){
            label.html($(this).val() + (postfix ? ' ' + postfix : ''));
        })

        label.html($(this).val() + (postfix ? ' ' + postfix : ''));
    });

    let $parametersForm = $('.parameters-form');

    $parametersForm
        .find('input[name=accepted_contracts]')
        .on('change', function (event) {
            $parametersForm
                .removeClass(['parameters-form--all', 'parameters-form--all-but-ctt'])
                .addClass('parameters-form--' + $(this).val())
            ;
        })
    ;

    $parametersForm
        .find('input[name=accepted_contracts]:checked')
        .change()
    ;

   let $completeRegistrationForm = $('.complete-registration-form');

    $completeRegistrationForm
        .find('input[name=accepted_contracts]')
        .on('change', function (event) {
            $completeRegistrationForm
                .removeClass(['complete-registration-form--all', 'complete-registration-form--all-but-ctt'])
                .addClass('complete-registration-form--' + $(this).val())
            ;
        })
    ;

    $completeRegistrationForm
        .find('input[accepted_contracts]:checked')
        .change()
    ;

    $('select[name=nationality_id]').each((idx, el) => {
        if ($(el).val() == '') {
            $(el).find($(el).find('option.nationality-F'))
                .insertBefore($(el).find('option:eq(1)'))
        }
    });

    if ($('select[name=birth_country_id]').find('option:selected').text() != 'France') {
        $('select[name=birth_department_id]').parent().addClass('d-none');
    }

    $('select[name=birth_country_id]').each((idx, el) => {
        if ($(el).val() == '') {
            $(el).find($(el).find('option.birthCountry-FR'))
                .insertBefore($(el).find('option:eq(1)'))
        }

        $(el).on('change', (event) => {
            if ($(event.target).find('option:selected').text() == 'France') {
                $('select[name=birth_department_id]').parent().removeClass('d-none');
            } else {
                $('select[name=birth_department_id]').parent().addClass('d-none');
            }
        });
    });

    $('#DiscoveredUsBy').on('change', function () {
        let options = Array.from(this.selectedOptions);
        let isOther = options.some(option => option.getAttribute('data-type') === 'Autre :');
        if(isOther) {
            $('#DiscoveredOther').toggleClass('d-none');
        } else {
            $('#DiscoveredOther').removeClass('d-block');
        }
    });
});
