/**
 * Summernote initialization
 */
import 'summernote';

$(() => {
    $('textarea.summernote').summernote({
        lang: 'fr-FR',
        disableDragAndDrop: true,
        toolbar: [
            ['font', ['bold', 'underline', 'clear']],
            ['para', ['ul', 'ol', 'paragraph']],
        ],
        popover: [],
        callbacks: {
            onPaste(event) {
                let bufferText = ((event.originalEvent || event).clipboardData || window.clipboardData)
                    .getData('text')
                ;

                event.preventDefault();

                let text = ('<p>' + bufferText.replace(/\n/g, '</p><p>') + '</p>');

                $(this).summernote('pasteHTML', text);
            },
        },
    });
});
