<template>
  <div>
    <select class="ajax-select2 form-control" :id="name" :disabled="isDisabled">
    </select>
  </div>
</template>

<script>
    import 'select2';
    export default {
        name: 'Select2',
        data() {
            return {
                selected: null
            }
        },
        props: {
            name: {
                default: "default"
            },
            dataUrl: {
                default: null
            },
            isDisabled: {
                default: false
            },
            initial: {
                default: null,
            }
        },
        mounted() {
            $(() => {
                $('#' + this.name).select2({
                    ...{
                        width: 'style',
                        templateResult: function(option) {
                            if(option.element && (option.element).hasAttribute('hidden')){
                                return null;
                            }
                            return option.text;
                        },
                    },
                    ajax: {
                        delay: 500,
                        url: this.dataUrl,
                        dataType: 'json',
                        data: function (params) {
                            return {
                                q: params.term,
                                page: params.page || 1,
                                perPage: 25,
                            }
                        },
                        processResults: function (data, params) {
                            return {
                                results: data.data,
                            };
                        },
                    },
                })

                if (this.initial) {
                    var newOption = new Option(this.initial.name, this.initial.id, false, false)
                    $('#' + this.name).append(newOption).trigger('change')
                }

                $('#' + this.name).on('select2:select', (e) =>
                    this.$emit('selected', e.params.data.id)
                )
            })
        }
    }
</script>

<style scoped lang="scss">
</style>
