$(() => {
    $('div[id^="WelcomeBookletModal-"] .modal-body').scroll(function (event) {
        let $modalBody = $(event.currentTarget);

        if ($modalBody.scrollTop() + $modalBody.height() > $modalBody.find('.booklet-content').first().height()) {
            $modalBody.siblings('.modal-footer')
                .find(".accept")
                .prop("disabled", false)
            ;
        }
    });

    document.querySelectorAll(".inputMediaDiploma").forEach( inputMedia => {
        $(inputMedia).on('change',function(e){
            if (e.target.files.length) {
                $('div.show .mediaDiplomaShow').html('Votre diplôme "' + e.target.files[0].name + '" a bien été téléchargé.');
            }
        });
    });
});

$(document).ready(function () {
    $('#AddMediaRequiredModal').on('show.bs.modal', function (event) {
        let mediaId = $(event.relatedTarget).data('media-id');
        var mediaName = $(event.relatedTarget).data('media-name');
        $('#mediaTitle').text(mediaName);
        $('#MediaInput2').val(mediaId);
    });
});
