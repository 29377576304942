<template>
    <div>
        <div :class="{ 'bg-nato-blue': 'current' === week, 'bg-fuel-yellow': 'next' === week }" class="text-center py-2 text-white h4 mb-0">
            <i class="fa-solid fa-calendar-days text-white mr-3"></i>
            {{ 'current' === week ? 'Semaine en cours' : 'Semaine à venir' }}
            <i title="Modifier" class="fa-solid fa-pen text-white ml-3 cursor-pointer" @click="editAvailabilities()"></i>
        </div>

        <div v-if="edit">
            <div v-for="(day, index) in periods" v-bind:key="index">
                <div v-show="index === step">
                    <div class="bg-nato-blue text-center py-2 text-white h4">
                        {{ day.label }}
                    </div>
                    <div class="px-5 py-3" v-for="(period, index) in day.periods" v-bind:key="index">
                        <div class="d-flex justify-content-between align-items-center">
                            <img class="pictos" height="24px" v-show="'MORNING' === period" :src="require('../../../images/pictos/morning.svg')" alt="" />
                            <i v-show="'AFTERNOON' === period" class="pictos fa-solid fa-2xl fa-sun"></i>
                            <i v-show="'EVENING' === period" class="pictos fa-solid fa-2xl fa-cloud-sun"></i>
                            <i v-show="'NIGHT' === period" class="pictos fa-solid fa-2xl fa-moon"></i>
                            <div class="d-flex">
                                <span>{{ i18n['periods'][period] }}</span>
                                <label class="toggle ml-2">
                                    <input type="checkbox" :checked="getInitialAvailability(day.formatted, period)" @click="updateAvailability(day.formatted, period)">
                                    <span class="slider-checkbox"></span>
                                    <span class="labels" data-on="OUI" data-off="NON"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-around m-4" >
                <div v-show="step === 0" @click="step = null; edit = false"><i class="fa-solid fa-circle-xmark h2 cursor-pointer color" title="Annuler"></i></div>
                <div v-show="step > 0 && step < 7" @click="step -= 1"><i class="fa-solid fa-circle-chevron-left h2 cursor-pointer color" title="Jour précédent"></i></div>
                <div v-show="step != null && step < 6" @click="step += 1"><i class="fa-solid fa-circle-chevron-right h2 cursor-pointer color" title="Jour suivant"></i></div>
                <div v-show="step === 6" @click="saveAvailabilities()"><i class="fa-regular fa-circle-check h2 cursor-pointer color" title="Valider"></i></div>
            </div>

            <div class="d-flex justify-content-center p-4">
                <a @click="clearAvailabilities()" class="btn btn-nato-blue">Je ne suis plus disponible</a>
            </div>
        </div>
        <div v-else>
            <div v-for="(day, index) in periods" v-bind:key="index">
                <div class="py-2 m-0 pl-4 d-flex justify-content-between">
                    <span class="h5">{{ day.label }}</span>
                    <span class="px-3 h5 color-caribe">{{ getDisplayedAvailabilities(day.formatted) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'StaffPlusAvailabilities',
        data() {
            return {
                edit: false,
                error: null,
                i18n: {
                    periods: {
                        'MORNING': 'Matin',
                        'AFTERNOON': 'Après-midi',
                        'EVENING': 'Soirée',
                        'NIGHT': 'Nuit',
                    },
                },
                initialAvailabilities: null,
                step: null,
            }
        },
        props: {
            availabilities: {
                type: Object,
                default: null,
            },
            periods: {
                type: Object,
                default: null
            },
            week: {
                type: String,
                default: 'current',
            },
        },
        methods: {
            clearAvailabilities() {
                this.availabilities = null
                this.step = null
                this.edit = false

                this.$emit('availability-cleared', this.availabilities)
            },

            getInitialAvailability(date, period) {
                if (!this.initialAvailabilities || !this.initialAvailabilities.periods) {
                    return false
                }

                return date in this.initialAvailabilities.periods
                    && this.initialAvailabilities.periods[date].includes(period)
            },

            getDisplayedAvailabilities(date) {
                if (!this.availabilities || !this.availabilities.periods || !this.availabilities.periods[date]) {
                    return ''
                }

                return  this.availabilities.periods[date]
                    .map(period => {
                        switch (period) {
                            case 'MORNING':
                                return 'matin'
                            case 'AFTERNOON':
                                return 'après-midi'
                            case 'EVENING':
                                return 'soir'
                            case 'NIGHT':
                                return 'nuit'
                            default:
                                return ''
                        }
                    })
                    .join(', ')
            },

            editAvailabilities() {
                this.edit = !this.edit
                this.step = 0
            },

            updateAvailability(day, availability) {
                if (!this.availabilities.periods) {
                    this.availabilities.periods = {}
                }

                if (!(day in this.availabilities.periods)) {
                    this.availabilities.periods[day] = [availability]
                } else if (this.availabilities.periods[day].includes(availability)) {
                    this.availabilities.periods[day] = this.availabilities
                        .periods[day]
                        .filter(item => item !== availability)
                } else {
                    this.availabilities.periods[day] = this.availabilities
                        .periods[day]
                        .concat([availability])
                }

                this.$emit('availability-updated', this.availabilities)
            },

            saveAvailabilities() {
                this.$emit('save-availabilities')
            }
        },
        mounted: function () {
            this.initialAvailabilities = JSON.parse(JSON.stringify(this.availabilities))
        }
    }
</script>

<style scoped lang="scss">
    .btn-white {
        color: white;
    }

    .md-column{
        @media (min-width: 768px) {
            flex-direction: column;
        }
    }

    .toggle {
      --width: 70px;
      --height: calc(var(--width) / 3);

      position: relative;
      display: inline-block;
      width: var(--width);
      height: var(--height);
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      border-radius: var(--height);
      cursor: pointer;
    }

    .toggle input {
      display: none;
    }

    .toggle .slider-checkbox {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: var(--height);
      background-color: #ccc;
      transition: all 0.4s ease-in-out;
    }

    .toggle .slider-checkbox::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(var(--height));
      height: calc(var(--height));
      border-radius: calc(var(--height) / 2);
      background-color: #fff;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      transition: all 0.4s ease-in-out;
    }

    .toggle input:checked+.slider-checkbox {
      background-color: #d48f31;
    }

    .toggle input:checked+.slider-checkbox::before {
      transform: translateX(calc(var(--width) - var(--height)));
    }

    .toggle .labels {
      position: absolute;
      top: 4px;
      left: 0;
      width: 100%;
      height: 100%;
      font-size: 12px;
      font-family: sans-serif;
      transition: all 0.4s ease-in-out;
    }

    .toggle .labels::after {
      content: attr(data-off);
      position: absolute;
      right: 5px;
      color: #707070;
      opacity: 1;
      transition: all 0.4s ease-in-out;
    }

    .toggle .labels::before {
      content: attr(data-on);
      position: absolute;
      left: 5px;
      color: #ffffff;
      opacity: 0;
      text-shadow: 1px 1px 2px  #aba3ae;
      transition: all 0.4s ease-in-out;
    }

    .toggle input:checked~.labels::after {
      opacity: 0;
    }

    .toggle input:checked~.labels::before {
      opacity: 1;
    }

    i {
        color: #132C45;
    }

    i.color:hover{
        color: #d48f31;
    }

    .day:nth-child(even){
        background-color: #f6f4e6;
    }
</style>
