import Availabilities from "../../components/Common/Availabilities.vue";
import NewPositionSelection from "../../components/Common/NewPositionSelection.vue";
import NotificationTray from "../../components/Common/NotificationTray.vue";
import Planning from "../../components/Common/Planning.vue";
import Registration from "../../components/Applicant/Registration.vue";
import Select2 from "../../components/Common/Select2.vue";
import StaffPlus from "../../components/Common/StaffPlus.vue";
import StaffPlusAvailabilities from "../../components/Common/StaffPlusAvailabilities.vue";
import TimeSheet from "../../components/Common/TimeSheet.vue";

export default {
    install(Vue) {
        Vue.component('availabilities', Availabilities);
        Vue.component('new-position-selection', NewPositionSelection);
        Vue.component('notification-tray', NotificationTray);
        Vue.component('planning', Planning);
        Vue.component('registration', Registration);
        Vue.component('select-2', Select2);
        Vue.component('staff-plus', StaffPlus);
        Vue.component('staff-plus-availabilities', StaffPlusAvailabilities);
        Vue.component('timesheet', TimeSheet);
    }
}
