$(() => {
    if (sessionStorage.openTab) {
        // active tab
        document.querySelectorAll(".media-tab").forEach(tab => {
            $(tab).removeClass("active");
            $(tab).attr("aria-selected", false);
        });
        $("#" + sessionStorage.openTab).find("a").trigger("click");
        $("#" + sessionStorage.openTab).addClass('active');
        $("#" + sessionStorage.openTab).attr("aria-selected", true);

        // active block
        document.querySelectorAll(".media-block").forEach(block => {
            $(block).removeClass("show active");
        });
        let idBlock = sessionStorage.openTab.substring(0, sessionStorage.openTab.length - 4);
        $("#" + idBlock).addClass('show active');
    }

    $(".media-tab").on("click", function() {
        sessionStorage.setItem("openTab",$(this).attr("id"));
    });

    $("#MediaType").on('change', function() {
        if ($(this).find("option:selected").val() == 'valletoux-certification') {
            $('.valletoux-div').show();
        } else {
            $('.valletoux-div').hide();
        }
    });

    $("#DashboardMedia").on("click", function() {
        sessionStorage.setItem("openTab",'pill-document-tab');
    });
});
