import Vue from 'vue';

window.Vue = Vue;

import registerPlugins from '@applicantPlugins';

registerPlugins(Vue);

new Vue({
    el: '#app',
});

import './common/bootstrap';
import './common/select2';
import './common/links';
import './common/show-password';
import './common/submit-button';
import './common/svg-circle-progress';
import './applicant/summernote';
import './applicant/profile/alert-modal';
import './applicant/profile/documents';
import './applicant/profile/medias';
import './applicant/dashboard';
import './applicant/forms';
import './applicant/upload-file';

$(() => {
    $('[data-toggle="tooltip"]').tooltip();

    $('.modal[data-open=true]').modal('show');
});
